import { render, staticRenderFns } from "./CreateAdditionalMaterial.vue?vue&type=template&id=23161108&scoped=true&"
import script from "./CreateAdditionalMaterial.vue?vue&type=script&lang=js&"
export * from "./CreateAdditionalMaterial.vue?vue&type=script&lang=js&"
import style0 from "./CreateAdditionalMaterial.vue?vue&type=style&index=0&id=23161108&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23161108",
  null
  
)

export default component.exports