<template>
  <div>
    <router-link class="come-back" to="/admin/additional_materials">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование дополнительного материала №${$route.params.id}` : 'Создание дополнительного материала' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createAdditionalMaterial" ref="createAdditionalMaterial">
        <div class="content-sb">
          <p>Заголовок<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="additionalMaterialData.title" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Заголовок ссылки<span>*</span></p>
          <input required placeholder="Введите заголовок описания" v-model="additionalMaterialData.link_title" type="text" name="link_title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Ссылка<span>*</span></p>
          <input required placeholder="Введите ссылку" v-model="additionalMaterialData.link" type="text" name="link" class="input blue">
        </div>

        <div class="content-sb">
          <p>Приоритет<span>*</span></p>
          <input required v-model="additionalMaterialData.order" min="1" max="1000" type="number" name="order" class="input blue">
        </div>

        <div class="content-sb">
          <p>Иконка<span>*</span></p>
          <custom-drop-zone :name="'icon'"
                @setFile="setFile($event)"
                :uploaded-blob="previewBlob"/>
        </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/additional_materials" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import CustomDropZone from '@/components/Admin/CustomDropZone'

export default {
  name: 'CreateAdditionalMaterial',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      previewBlob: null,
      additionalMaterialData: {
        title: null,
        link_title: null,
        link: null,
        order: 1000,
        icon: null
      }
    }
  },

  watch: {
    '$store.getters.getDetailedAdditionalMaterial' () {
      const dataFromServer = this.$store.getters.getDetailedAdditionalMaterial

      this.additionalMaterialData = {
        title: dataFromServer.title,
        link_title: dataFromServer.link_title,
        link: dataFromServer.link,
        order: dataFromServer.order
      }
      this.previewBlob = dataFromServer.icon
      this.$forceUpdate()
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('setDetailedAdditionalMaterial', this.$route.params.id)
    }
  },

  methods: {
    createAdditionalMaterial () {
      const formData = new FormData()
      formData.append('title', this.additionalMaterialData.title)
      formData.append('link_title', this.additionalMaterialData.link_title)
      formData.append('link', this.additionalMaterialData.link)
      formData.append('order', this.additionalMaterialData.order)

      if (!this.isEdit) {
        formData.append('icon', this.additionalMaterialData.icon)
        this.$store.dispatch('createAdditionalMaterial', formData)
      } else {
        if (this.additionalMaterialData.icon) {
          formData.append('icon', this.additionalMaterialData.icon)
        }
        formData.append('_method', 'PUT')
        this.$store.dispatch('editAdditionalMaterial', {
          data: formData,
          id: this.$route.params.id
        })
      }
    },
    setFile (file) {
      this.previewBlob = null
      this.additionalMaterialData.icon = file
    }
  },
  components: {
    TricolorLine,
    CustomDropZone
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .contacts__block {
    border-bottom: 1px solid #D5D6D9;
    margin-bottom: 1rem;

    header {
      margin-bottom: .75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h4 {
        font-size: .875rem;
        line-height: 1.375rem;
      }

      > img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
